export default {
  props: ["nameScreen"],
  data() {
    return {
      menu: false,
      sSearch: "",
      dates: null,
      dateStart: null,
      dateEnd: null,
      texts: "",
      sCategoryType: { sName: 'Todo', sCategoryTypeId: '' },
      aCategoryType: [ ],
    };
  },
  mounted() {
    this.$store.commit("setSearch", "");
    // this.$store.commit("setStartDate", null);
    // this.$store.commit("setEndDate", null);
  },
  beforeMount() {
    this.texts = FILE.categoryTexts[this.selectLanguage];
    this.categoryType();
  },
  methods: {

    categoryType(){
      DB.get(`${URI}/api/v1/${this.selectLanguage}/types`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
        },
      })
        .then((response) => {
          this.aCategoryType = response.data.results;
          if(this.selectLanguage =='sp'){
            this.aCategoryType.unshift({sName:'Todos',sCategoryTypeId:''})    
          }else{
            this.aCategoryType.unshift({sName:'All',sCategoryTypeId:''})    
          }
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    buttonAdd() {
      this.$store.commit("setDialogAddCategory", true);
    },
    setDates() {
      this.dateStart = this.dates[0];
      this.dateEnd = this.dates[1];
    },
    focusSearch() {
      this.$refs.sSearch.focus();
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    dateStart: function () {
      // this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      // this.$store.commit("setEndDate", this.dateEnd);
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.categoryTexts[this.selectLanguage];
        this.categoryType();
      }
    },
    // set state to local search
    sSearch: function () {
      this.$store.commit("setSearch", this.sSearch);
    },
    sCategoryType: function(){
      this.$store.commit("setsCategoryId",this.sCategoryType);
      this.$store.commit("refresher", true);
    }
  },
};